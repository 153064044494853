/* Styles de base pour la modale */
.modale {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 59, 94);
  color: white; /* Assure que le texte est bien visible sur le fond sombre */
  padding: 20px;
  z-index: 1000;
  text-align: center;
  border-radius: 8px; /* Ajoute des coins arrondis à la modale */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajoute une ombre pour un effet de profondeur */
  /* max-width: 500px;
  width: 90%;  */
  display: flzex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  display: flex;

}

.section1 {
  display: flex;
}

/* Fond sombre pour l'arrière-plan de la modale */
.fondModale {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Légèrement plus clair pour un effet de transparence */
  z-index: 999;
}



/* Styles spécifiques pour la photo de profil */
.modale .photoProfile {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
}

/* Espacements pour les titres et les paragraphes */
.modale h2, .modale p {
  margin: 10px 0;
  padding: 0px 20px 0px 20px;
 max-width: 350px;
}

/* Styles pour les liens */
.modale a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Styles supplémentaires pour améliorer la lisibilité et l'aspect visuel */
.modale p {
  line-height: 1.6; /* Augmente l'espacement des lignes pour améliorer la lisibilité */
}

/* Bouton de fermeture pour un style plus intuitif */
.modale button {
  cursor: pointer; /* Change le curseur pour indiquer un élément cliquable */
  background-color: #ff4757; /* Couleur de fond pour le bouton de fermeture */
  color: white; /* Couleur du texte pour le bouton */
  border: none; /* Supprime la bordure du bouton */
  padding: 10px 20px; /* Padding intérieur pour agrandir le bouton */
  border-radius: 5px; /* Coins arrondis pour le bouton */
  margin-bottom: 20px; /* Espacement en dessous du bouton pour séparer du contenu */
  font-size: 16px; /* Taille du texte pour le bouton */
}


.présentation {
  margin-bottom: 10px;
}

.entreprise {
  padding: 10px 0px 10px 0px;
}

