body {
    font-family: Arial, sans-serif;
    background-color: rgb(27, 69, 95);
    color: white;
    
  }

  h1 {
    margin-left: 20px;
  }
  
  /* Ajoutez plus de styles selon vos besoins */
  
  .carte-personne {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  
  }
  
  
  .carte-personne h2 {
    margin: 20 0 20px 0;
  }
  
 /* Ajoutez ces styles pour le champ de recherche */
.search-container {
  margin-left: 40px; /* Marge de gauche */
}

.search-input {
  width: 250px; /* Largeur légèrement agrandie */
  padding: 8px; /* Espace interne pour le champ de recherche */
  border: 1px solid #ccc; /* Bordure du champ de recherche */
  border-radius: 4px; /* Coins arrondis */
}

