.liste-personnes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .carte-personne {
    flex-basis: 30%; /* Ajustez selon la taille souhaitée */
    margin: 10px;
    /* autres styles pour carte-personne */
  }
  
  /* Styles pour la mise en page responsive */
@media (max-width: 768px) {
  .carte-personne {
    flex-basis: 100%; /* Les cartes occupent toute la largeur à moins de 768px */
  }
}

/* Ajoutez d'autres règles de médias queries au besoin */
