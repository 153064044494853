/* Style de base pour les cartes */
.carte-personne {
  display: flex;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  position: relative;
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  justify-content: space-around;
  background-color: rgb(21, 95, 129);
}
/* Styles pour l'icône de l'utilisateur */
.icon-user {
  
 
  margin-right: 10px; /* Ajoutez de l'espace à droite de l'icône */
}

/* Styles pour l'icône de l'entreprise */
.icon-building {
 
  margin-right: 10px; /* Ajoutez de l'espace à droite de l'icône */
}



.carte-personne::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0; /* Le voile est initialement transparent */
  transition: opacity 0.3s ease;
}

.carte-personne:hover::before {
  opacity: 0.5; /* Le voile devient visible au survol */
}

.photoPers {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto; /* Centre l'image horizontalement */
}

.info-gauche,
.info-droite {
  display: flex; /* Utilise flexbox pour organiser les éléments en ligne */
  flex-direction: column; /* Aligne les éléments en colonnes */
  text-align: left;
  padding: 5px;
}

.info-droite {
  padding: 2%;
  font-size: 14px;
  max-width: 250px;
  margin-top: 15px;
  gap: 1px;
}
.info-gauche {
  text-align: center;
  max-width: 200px;
  margin-top: 5px;

}

.carte-personne h2 {
  margin: 10px 0;
}

.carte-personne p {
  margin: 5px 0;
}

